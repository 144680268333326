import translations from "../translations";

const Footer = ({language}) => {
    const createdText = translations[language].created;
    const autorText = translations[language].autor;

    return (
        <footer>
            <div className="footer">
                <span className="version"><i>v0.0.1</i></span>
                <div>
                    {createdText} <a href="https://romakhlebnikov.notion.site/romakhlebnikov/38f655a889ed4cd6a15c7b1e81cc8572" rel="noreferrer" target="_blank"> {autorText}</a> 🖖
                </div>
            </div>
        </footer>
     );
}

export default Footer;
