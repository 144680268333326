import React, { useEffect, useState, useCallback } from "react";
import Header from "./components/Header";
import Card from "./components/Card";
import Footer from "./components/Footer";
import Popup from "./components/Popup";
import Tabs from "./components/Tabs";
// import Skeleton from 'react-loading-skeleton';

import "./styles/main.css";
import "./img/icons/close.svg";

function App() {
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const savedLanguage = localStorage.getItem("language") || "RU";
  const savedActiveTab = localStorage.getItem("activeTab") || "All";
  const [activeTab, setActiveTab] = useState(savedActiveTab);
  const [showPopup, setShowPopup] = useState(false);
  const [language, setLanguage] = useState(savedLanguage);
  const [dataLoaded, setDataLoaded] = useState(false);

  const fetchCards = useCallback(async () => {
    const SPREADSHEET_ID = "1F6FuxbUvPoWGRTPU6mY-KxkfRGUc0b7xAm99pagHNcg";
    const sheetName = language === "RU" ? "RU" : "EN";

    const response = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values:batchGet?ranges=${sheetName}!A2:G&majorDimension=ROWS&key=AIzaSyAgiNbi2C2dJBC_7aKfLvIzKDuK3Ka4P1M`
    );

    console.log(response);

    const data = await response.json();
    const rows = data.valueRanges[0].values;

    const cards = rows.map((row) => ({
      title: row[0],
      description: row[1],
      example: row[2],
      autors: row[3],
      links: row[4],
      image: row[5],
      group: row[6],
    }));

    setCards(cards);
    setDataLoaded(true);
  }, [language]);

  useEffect(() => {
    fetchCards();
    // console.log('Fetching cards...');
  }, [language, activeTab, fetchCards]);

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setShowPopup(true); // Открытие popup
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Закрытие popup
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleLanguageChange = () => {
    const newLanguage = language === "RU" ? "EN" : "RU";
    const newTabs = tabs[newLanguage];

    const activeTabIndex = newTabs.indexOf(activeTab);
    const newActiveTab = activeTabIndex !== -1 ? activeTab : newTabs[0];

    localStorage.setItem("language", newLanguage);
    localStorage.setItem("activeTab", newActiveTab);

    setLanguage(newLanguage);
    setActiveTab(newActiveTab);

    fetchCards();

    window.location.reload();
  };

  const tabs = {
    RU: ["Все", "Самые частые", "Восприятие", "Информация", "Память", "Эмоции"],
    EN: ["All", "Top common", "Perception", "Information", "Memory", "Emotion"],
  };

  return (
    <div className="App">
      <div className="wrap">
        {selectedCard && (
          <Popup
            showPopup={showPopup}
            title={selectedCard.title}
            description={selectedCard.description}
            image={selectedCard.image}
            links={selectedCard.links}
            example={selectedCard.example}
            group={selectedCard.group}
            onClose={handleClosePopup}
            language={language}
          />
        )}

        <Header onLanguageChange={handleLanguageChange} language={language} />

        <Tabs
          tabs={tabs[language]}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          language={language}
        />

        <div className="card-container">
          {cards.length > 0 ? (
            cards
              .filter(
                (card) =>
                  activeTab.toLowerCase() === "все" ||
                  activeTab.toLowerCase() === "all" ||
                  (card.group &&
                    card.group.toLowerCase().includes(activeTab.toLowerCase()))
              )
              .map((card) => (
                <Card
                  key={card.title}
                  title={card.title}
                  description={card.description}
                  example={card.example}
                  image={card.image}
                  onClick={() => handleCardClick(card)}
                />
              ))
          ) : (
            <div className="loader"></div>
          )}
        </div>
        {dataLoaded && (
          <Footer
            language={language}
          />
        )}
      </div>
    </div>
  );
}

export default App;
