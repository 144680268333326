import React from "react";
import translations from "../translations";
// import logo from "./../img/icons/logo.png";

const Header = ({ onLanguageChange, language }) => {
    const languageText = language === "RU" ? "🇬🇧 eng" : "🕊️ rus";
    const cognitiveText = translations[language].cognitive;
    // const biasesText = translations[language].biases;

    const handleLogoClick = () => {
        window.location.reload();
    };

    return (
        <header className="header">
            <div className="header__wrapper">
                <div className="navigation">
                    <div className="nav__wrapper">
                        <div className="logo" onClick={handleLogoClick}>
                            <svg
                                width="40"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="12"
                                    cy="3.61905"
                                    r="2.61905"
                                    fill="#FF5E03"
                                />
                                <path
                                    d="M6.2381 11.9999C6.2381 13.4464 5.06551 14.619 3.61905 14.619C2.17259 14.619 1 13.4464 1 11.9999C1 10.5534 2.17259 9.38086 3.61905 9.38086C5.06551 9.38086 6.2381 10.5534 6.2381 11.9999Z"
                                    fill="url(#paint0_linear_450_2300)"
                                />
                                <path
                                    d="M14.619 11.9999C14.619 13.4464 13.4465 14.619 12 14.619C10.5535 14.619 9.38095 13.4464 9.38095 11.9999C9.38095 10.5534 10.5535 9.38086 12 9.38086C13.4465 9.38086 14.619 10.5534 14.619 11.9999Z"
                                    fill="url(#paint1_linear_450_2300)"
                                />
                                <path
                                    d="M20.381 14.619C21.8274 14.619 23 13.4464 23 11.9999C23 10.5534 21.8274 9.38086 20.381 9.38086C18.9345 9.38086 17.7619 10.5534 17.7619 11.9999C17.7619 13.4464 18.9345 14.619 20.381 14.619Z"
                                    fill="url(#paint2_linear_450_2300)"
                                />
                                <path
                                    d="M20.381 22.9999C21.8274 22.9999 23 21.8273 23 20.3809C23 18.9344 21.8274 17.7618 20.381 17.7618C18.9345 17.7618 17.7619 18.9344 17.7619 20.3809C17.7619 21.8273 18.9345 22.9999 20.381 22.9999Z"
                                    fill="url(#paint3_linear_450_2300)"
                                />
                                <path
                                    d="M12 22.9999C13.4465 22.9999 14.619 21.8273 14.619 20.3809C14.619 18.9344 13.4465 17.7618 12 17.7618C10.5535 17.7618 9.38095 18.9344 9.38095 20.3809C9.38095 21.8273 10.5535 22.9999 12 22.9999Z"
                                    fill="url(#paint4_linear_450_2300)"
                                />
                                <path
                                    d="M3.61905 22.9999C5.06551 22.9999 6.2381 21.8273 6.2381 20.3809C6.2381 18.9344 5.06551 17.7618 3.61905 17.7618C2.17259 17.7618 1 18.9344 1 20.3809C1 21.8273 2.17259 22.9999 3.61905 22.9999Z"
                                    fill="url(#paint5_linear_450_2300)"
                                />
                                <defs>
                                    <linearGradient
                                        id="paint0_linear_450_2300"
                                        x1="11.4762"
                                        y1="4.66657"
                                        x2="11.4762"
                                        y2="27.5"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop
                                            offset="0.172248"
                                            stop-color="white"
                                        />
                                        <stop offset="1" />
                                    </linearGradient>
                                    <linearGradient
                                        id="paint1_linear_450_2300"
                                        x1="11.4762"
                                        y1="4.66657"
                                        x2="11.4762"
                                        y2="27.5"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop
                                            offset="0.172248"
                                            stop-color="white"
                                        />
                                        <stop offset="1" />
                                    </linearGradient>
                                    <linearGradient
                                        id="paint2_linear_450_2300"
                                        x1="11.4762"
                                        y1="4.66657"
                                        x2="11.4762"
                                        y2="27.5"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop
                                            offset="0.172248"
                                            stop-color="white"
                                        />
                                        <stop offset="1" />
                                    </linearGradient>
                                    <linearGradient
                                        id="paint3_linear_450_2300"
                                        x1="11.4762"
                                        y1="4.66657"
                                        x2="11.4762"
                                        y2="27.5"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop
                                            offset="0.172248"
                                            stop-color="white"
                                        />
                                        <stop offset="1" />
                                    </linearGradient>
                                    <linearGradient
                                        id="paint4_linear_450_2300"
                                        x1="11.4762"
                                        y1="4.66657"
                                        x2="11.4762"
                                        y2="27.5"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop
                                            offset="0.172248"
                                            stop-color="white"
                                        />
                                        <stop offset="1" />
                                    </linearGradient>
                                    <linearGradient
                                        id="paint5_linear_450_2300"
                                        x1="11.4762"
                                        y1="4.66657"
                                        x2="11.4762"
                                        y2="27.5"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop
                                            offset="0.172248"
                                            stop-color="white"
                                        />
                                        <stop offset="1" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <button className="lang" onClick={onLanguageChange}>
                            {languageText}
                        </button>
                    </div>
                    <hr className="separator"></hr>
                </div>
                <h1 className="header__title">
                    {cognitiveText}{" "}
                    {/* <div className="hero glitch layers" data-text={biasesText}>
                        <span>{biasesText}</span>
                    </div> */}
                </h1>
            </div>
        </header>
    );
};

export default Header;
