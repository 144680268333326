function Card(props) {
    const { onClick } = props;

    return (
        <div className="card" onClick={onClick}>
            <div className="emoji">{props.image}</div>
            <h2>{props.title}</h2>
            <p>{props.description}</p>
        </div>
    );
  }
  
// const Card = ({ icon, title, description }) => {
//     return ( 
//         <div className="card">
//             <img src={icon} alt="icon" />
//             <h2>{title}</h2>
//              <p>{description}</p>

//             <h2>Заголовок</h2>
//             <p>Описание</p>
//         </div>
//      );
// }
 
export default Card;
