
const translations = {
  EN: {
    cognitive: "Cognitive biases",
    biases: "biases",
    example: "Example",
    wikipedia: "Wikipedia",
    created: "Created by",
    autor: "Roma Khlebnikov",
  },
  RU: {
    cognitive: "Когнитивные искажения",
    biases: "искажения",
    example: "Пример",
    wikipedia: "Википедия",
    created: "Создано",
    autor: "Ромой Хлебниковым",
  },
};

export default translations;
