import React, { useEffect, useState } from "react";
import close from "./../img/icons/close.svg";
import outside from "./../img/icons/outside-link.svg";
import translations from "../translations";

const Popup = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const exampleText = translations[props.language].example;
  const wikipediaText = translations[props.language].wikipedia;

  useEffect(() => {
    setIsOpen(props.showPopup);
    document.body.style.overflow = props.showPopup ? "hidden" : "auto";
  }, [props.showPopup]);

  const handleClosePopup = () => {
    setIsClosing(true);
    setIsOpen(false);
    setIsClosing(false);
    props.onClose();
  };

  return (
    <>
      {isOpen && (
        <>
          <div
            className={`overlay ${isClosing ? "closing" : ""}`}
            onClick={handleClosePopup}
          ></div>
          <div
            className={`popup ${isOpen ? "show" : ""} ${
              isClosing ? "closing" : ""
            }`}
          >
            <div className="popup-content">
              <div className="popup-header">
                <div className="emoji2">{props.image}</div>
                <h2>{props.title}</h2>
                <p>{props.description}</p>
              </div>
              <div className="popup-body">
                <h3>{exampleText}</h3>
                <p>{props.example}</p>
              </div>
              <div className="wiki">
                <a className="link" href={props.links} rel="noreferrer" target="_blank"> {wikipediaText}</a>
                <img className="icon" src={outside} alt="Outside"></img>
              </div>
              <button onClick={handleClosePopup}>
                <img className="icon" src={close} alt="Close"></img>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Popup;
