import React, { useState, useEffect } from "react";

function Tabs({ tabs, activeTab, onTabChange, language }) {
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    // При первом рендере устанавливаем активный таб "All"
    if (initialRender) {
      const defaultTab = language === "RU" ? "Все" : "All";
      onTabChange(defaultTab);
      setInitialRender(false);
    }
  }, [initialRender, onTabChange, language]);

  const handleTabClick = (tab) => {
    onTabChange(tab);
  };

  const localizedTabs = language === "RU" ? tabs : getLocalizedTabs(tabs);

  return (
    <div className="tabs-wrapper">
      <div className="tabs-container">
        {localizedTabs.map((tab) => (
          <div
            key={tab}
            className={`tab ${tab === activeTab ? 'active' : ''}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
    </div>
  );
}

const getLocalizedTabs = (tabs) => {
  // Здесь можно реализовать логику локализации табов для других языков
  return tabs;
}

export default Tabs;
